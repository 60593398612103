import { Component, Inject, OnInit } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { Examination } from './examination';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UpdatePriceListRequest } from './updatepricelistrequest';
import { UpdatePriceListResponse } from './updatepricelistresponse';
import { GetSpecialityRequest } from './getspecialityrequest';
import { GetSpecialityResponse } from './getspecialityresponse';
import { DeletepricepopupComponent } from './deletepricepopup/deletepricepopup.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-pricelist',
    templateUrl: './pricelist.component.html',
    styleUrls: ['./pricelist.component.scss']
})
export class PricelistComponent implements OnInit {

    examinationList?: Examination[];

    regeneroBaseUrl = '';

    specialityId = 0;

    priceListForm?: FormGroup;

    specialityName = '';

    updatePriceListUrl = '/admin/update-pricelist';

    getSpecialityByIdUrl = '/admin/get-speciality-by-id';

    isExtraSmall: Observable<BreakpointState>;

    searchText = '';

    get items() {
        return this.priceListForm?.get('items') as FormArray;
    }

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private fb: FormBuilder,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;
        this.createPriceListFormArray();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            const routerLink = lastSegmentOfUrl as string;
            this.specialityId = +routerLink;
            this.getSpecialityById();
        }

    }

    createPriceListFormArray() {
        this.priceListForm = this.fb.group({
            items: this.fb.array([])
        });
    }


    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    updatePriceList() {
        let examinationList = this.items.value as Examination[];
        examinationList.forEach((examination) => {
            this.examinationList?.forEach((examination2, index) => {
                if (examination.id === examination2.id) {
                    examination2.maxPrice = examination.maxPrice;
                    examination2.minPrice = examination.minPrice;
                    examination2.price = examination.price;
                    examination2.name = examination.name;
                    examination2.orderNumber = index;
                    examination2.specialityId = examination.specialityId
                }
            })
        })

        const updatePriceListRequest: UpdatePriceListRequest = {
            examinationList: this.examinationList
        }

        this._apiRequestService
            .sendRequest<UpdatePriceListRequest, UpdatePriceListResponse>(
                this.regeneroBaseUrl,
                true,
                updatePriceListRequest,
                true,
                this.updatePriceListUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: UpdatePriceListResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                window.location.reload();
            });
    }

    getSpecialityById() {

        const getspecialitybyidRequest: GetSpecialityRequest = {
            specialityId: this.specialityId
        };
        this._apiRequestService
            .sendRequest<GetSpecialityRequest, GetSpecialityResponse>(
                this.regeneroBaseUrl,
                true,
                getspecialitybyidRequest,
                true,
                this.getSpecialityByIdUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetSpecialityResponse) => {
                this.specialityName = response.speciality?.name!;
                this.examinationList = response.speciality?.examinationList;
                this.priceListForm = this.fb.group({
                    items: this.fb.array(this.examinationList!.map(examination => this.fb.group(examination)))
                });
            });
    }

    openDeleteExaminationPopup(examinationId: number) {
        const popupDialog = this._matDialog.open(DeletepricepopupComponent, {
            data: examinationId,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.examinationList!, event.previousIndex, event.currentIndex);
    }
}
